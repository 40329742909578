import type { ComponentProps } from 'react'
import dynamic from 'next/dynamic'
import { useMediaQuery } from 'react-responsive'

import type { Breakpoints } from '@acme/shared'

const MediaQuery = dynamic(() => import('react-responsive'), {
  ssr: false,
})

export const Responsive = (
  props: Omit<ComponentProps<typeof MediaQuery>, 'maxWidth' | 'minWidth'> & {
    maxWidth?: number
    minWidth?: number
  },
) => (
  <MediaQuery
    {...props}
    maxWidth={props.maxWidth !== undefined ? props.maxWidth - 1 : undefined}
    minWidth={props.minWidth}
  />
)

export const useMaxWidth = (breakpoint: Breakpoints) => {
  return useMediaQuery({ query: `(max-width: ${breakpoint - 1}px)` })
}

export const useMinWidth = (breakpoint: Breakpoints) => {
  return useMediaQuery({ query: `(min-width: ${breakpoint}px)` })
}
