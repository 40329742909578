import { cn } from '@/lib/utils'

import { TEAM_MEMBERS } from '@acme/shared'

import { COUNTRIES } from '~/files/countries'
import { isCountryFeature, isStateFeature } from '~/files/country_types'
import { store } from '~/utils/store'

export const Team = () => {
  const { teamCutoffRef } = store.useSnapshot()
  return (
    <>
      <h2
        ref={teamCutoffRef}
        className={cn(
          'font-bold tracking-tight text-white ',
          'text-4xl sm:text-5xl',
        )}
      >
        Our team
      </h2>
      <p
        className={cn(
          'mt-6 leading-8 text-gray-300',
          'text-lg sm:text-xl md:text-2xl',
        )}
      >
        We{"'"}re a dynamic group of individuals who are passionate about what
        we do and dedicated to delivering the best results for our clients.
      </p>
      <ul className='mx-auto mt-20 flex max-w-2xl flex-row flex-wrap gap-x-8 gap-y-16 text-center lg:mx-0 lg:max-w-none'>
        {TEAM_MEMBERS.map((person) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
          <li
            key={person.id}
            className='cursor-pointer'
            onClick={() => {
              const selectedFeature = COUNTRIES.features.find((c) =>
                isCountryFeature(c)
                  ? person?.locations.includes(c.properties.ISO_A3)
                  : isStateFeature(c)
                  ? person?.locations.includes(c.properties.name)
                  : false,
              )
              if (selectedFeature) {
                store.set('globeMode', 'team')
                store.set('selectedProjectFeature', null)
                store.set('selectedTeamMemberFeature', {
                  teamMember: person,
                  feature: selectedFeature,
                })
              }
            }}
          >
            {/* <img
              className='mx-auto h-24 w-24 rounded-full'
              src={person.imageUrl}
              alt=''
            /> */}
            <h3 className='mt-0 text-base font-semibold leading-7 tracking-tight text-white'>
              {person.name}
            </h3>
            <p className='text-sm leading-6 text-gray-300'>{person.title}</p>
          </li>
        ))}
      </ul>
    </>
  )
}
