import { cn } from '@/lib/utils'

import { store } from '~/utils/store'

export const Subtitle = () => {
  return (
    <>
      <h2
        className={cn(
          'font-bold tracking-tight text-white ',
          'text-4xl sm:text-5xl',
        )}
      >
        Our mission
      </h2>
      <div
        className={cn(
          'flex flex-col py-8',
          'text-xl sm:text-3xl md:text-4xl',
          'gap-0 sm:gap-2 md:gap-3',
          'text-gray-300',
        )}
      >
        <p className='w-max cursor-pointer hover:text-blue-300'>
          • deliver excellent software
        </p>
        <p className='w-max cursor-pointer hover:text-blue-300'>
          • with global impact
        </p>
        <p className='w-max cursor-pointer hover:text-blue-300'>
          • via collaborative innovation
        </p>
        <p
          className='w-max cursor-pointer hover:text-blue-300'
          onMouseOver={() => store.set('hoverHighCountry', true)}
          onFocus={() => store.set('hoverHighCountry', true)}
          onBlur={() => store.set('hoverHighCountry', false)}
          onMouseLeave={() => store.set('hoverHighCountry', false)}
        >
          • from the High Country
        </p>
      </div>
    </>
  )
}
