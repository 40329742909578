import { useCallback, useEffect } from 'react'
import { cn } from '@/lib/utils'
import type { EmblaCarouselType, EmblaEventType } from 'embla-carousel-react'
import useEmblaCarousel from 'embla-carousel-react'

import { COUNTRIES } from '~/files/countries'
import { isCountryFeature, isStateFeature } from '~/files/country_types'
import { api } from '~/utils/api'
import { store } from '~/utils/store'
import { MdLoadingIndicator } from './MdLoadingIndicator'

export const Projects = () => {
  //https://www.embla-carousel.com/guides/slide-gaps/
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true })
  const { data: projects, isLoading } = api.project.list.useQuery()
  const { selectedProjectFeature, globeSpins } = store.useSnapshot()

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])

  const playPause = useCallback(() => {
    store.set('globeSpins', !globeSpins)
  }, [globeSpins])

  const onSelect = useCallback(
    (emblaApi: EmblaCarouselType, evt: EmblaEventType) => {
      console.log(
        `Embla just triggered ${evt}, ${emblaApi.selectedScrollSnap()}!`,
      )
      const selectedProjectFeature = store.get('selectedProjectFeature')
      const emblaApiSelectedProject = projects?.[emblaApi.selectedScrollSnap()]
      if (emblaApiSelectedProject?.id === selectedProjectFeature?.project.id)
        return

      const selectedFeature = COUNTRIES.features.find((c) =>
        isCountryFeature(c)
          ? emblaApiSelectedProject?.locations.includes(c.properties.ISO_A3)
          : isStateFeature(c)
          ? emblaApiSelectedProject?.locations.includes(c.properties.name)
          : false,
      )
      if (selectedFeature && emblaApiSelectedProject) {
        store.set('selectedProjectFeature', {
          project: emblaApiSelectedProject,
          feature: selectedFeature,
        })
      }
    },
    [projects],
  )

  useEffect(() => {
    if (emblaApi) emblaApi.on('select', onSelect)
  }, [emblaApi, onSelect])

  // Scroll to the selected project
  useEffect(() => {
    if (!selectedProjectFeature || !projects || !emblaApi) return
    const index = projects.findIndex(
      (p) => p.id === selectedProjectFeature?.project.id,
    )
    if (index >= 0 && emblaApi.selectedScrollSnap() !== index) {
      emblaApi.scrollTo(index, true)
    }
  }, [emblaApi, projects, selectedProjectFeature])

  return (
    <>
      <div className='flex justify-between'>
        <h2
          className={cn(
            'font-bold tracking-tight text-white ',
            'text-4xl sm:text-5xl',
          )}
        >
          Our projects
        </h2>
        <div className='z-10 flex flex-row items-center justify-between gap-2'>
          <button
            className='flex h-12 w-12 items-center justify-center rounded-full bg-white/10 pr-[2px] text-2xl hover:bg-white/20'
            onClick={scrollPrev}
          >
            <svg
              width='25'
              height='25'
              viewBox='0 0 15 15'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M6.85355 3.14645C7.04882 3.34171 7.04882 3.65829 6.85355 3.85355L3.70711 7H12.5C12.7761 7 13 7.22386 13 7.5C13 7.77614 12.7761 8 12.5 8H3.70711L6.85355 11.1464C7.04882 11.3417 7.04882 11.6583 6.85355 11.8536C6.65829 12.0488 6.34171 12.0488 6.14645 11.8536L2.14645 7.85355C1.95118 7.65829 1.95118 7.34171 2.14645 7.14645L6.14645 3.14645C6.34171 2.95118 6.65829 2.95118 6.85355 3.14645Z'
                fill='currentColor'
                fillRule='evenodd'
                clipRule='evenodd'
              ></path>
            </svg>
          </button>
          <button
            className='flex h-12 w-12 items-center justify-center rounded-full bg-white/10 pl-[2px] text-2xl hover:bg-white/20'
            onClick={playPause}
          >
            {!globeSpins ? (
              <svg
                width='25'
                height='25'
                viewBox='0 0 15 15'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M3.24182 2.32181C3.3919 2.23132 3.5784 2.22601 3.73338 2.30781L12.7334 7.05781C12.8974 7.14436 13 7.31457 13 7.5C13 7.68543 12.8974 7.85564 12.7334 7.94219L3.73338 12.6922C3.5784 12.774 3.3919 12.7687 3.24182 12.6782C3.09175 12.5877 3 12.4252 3 12.25V2.75C3 2.57476 3.09175 2.4123 3.24182 2.32181ZM4 3.57925V11.4207L11.4288 7.5L4 3.57925Z'
                  fill='currentColor'
                  fillRule='evenodd'
                  clipRule='evenodd'
                ></path>
              </svg>
            ) : (
              <svg
                width='25'
                height='25'
                viewBox='0 0 15 15'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M6.04995 2.74998C6.04995 2.44623 5.80371 2.19998 5.49995 2.19998C5.19619 2.19998 4.94995 2.44623 4.94995 2.74998V12.25C4.94995 12.5537 5.19619 12.8 5.49995 12.8C5.80371 12.8 6.04995 12.5537 6.04995 12.25V2.74998ZM10.05 2.74998C10.05 2.44623 9.80371 2.19998 9.49995 2.19998C9.19619 2.19998 8.94995 2.44623 8.94995 2.74998V12.25C8.94995 12.5537 9.19619 12.8 9.49995 12.8C9.80371 12.8 10.05 12.5537 10.05 12.25V2.74998Z'
                  fill='currentColor'
                  fillRule='evenodd'
                  clipRule='evenodd'
                ></path>
              </svg>
            )}
          </button>
          <button
            className='flex h-12 w-12 items-center justify-center rounded-full bg-white/10 pl-[2px] text-2xl hover:bg-white/20'
            onClick={scrollNext}
          >
            <svg
              width='25'
              height='25'
              viewBox='0 0 15 15'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8H2.5C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7H11.2929L8.14645 3.85355C7.95118 3.65829 7.95118 3.34171 8.14645 3.14645Z'
                fill='currentColor'
                fillRule='evenodd'
                clipRule='evenodd'
              ></path>
            </svg>
          </button>
        </div>
      </div>
      {isLoading ? (
        <MdLoadingIndicator className='fill-blue-300' />
      ) : (
        <div className='overflow-hidden py-8' ref={emblaRef}>
          <div className='flex h-80'>
            {projects
              ?.filter((p) => p.visible)
              .map((project) => (
                <ProjectSlide
                  key={project.id}
                  title={project.title}
                  subtitle={project.details?.subtitle}
                  description={project.details?.short_description_text}
                />
              ))}
          </div>
        </div>
      )}
    </>
  )
}

const truncateIfLong = (text: string, length: number) => {
  // truncate text at the last space before length
  if (text.length <= length) return text
  const truncated = text.slice(0, length).trimEnd()
  const lastSpace = truncated.lastIndexOf(' ')
  return truncated.slice(0, lastSpace) + '…'
}

const ProjectSlide = ({
  children,
  title,
  subtitle,
  description,
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  subtitle?: string
  description?: string
}) => {
  return (
    <div
      className={cn('relative w-full flex-shrink-0 flex-grow-0', className)}
      {...props}
    >
      <div className='absolute inset-0'>
        <div className='pb-2 text-3xl font-semibold'>{title}</div>
        {subtitle ? (
          <div className='pb-2 text-2xl text-gray-300'>{subtitle}</div>
        ) : null}
        {description ? (
          <div className='whitespace-pre-line text-xl font-extralight text-gray-300'>
            {truncateIfLong(description, 300)}
          </div>
        ) : null}
        {children}
      </div>
    </div>
  )
}

// const HoveredProject = () => {
//   const { hoveredFeature } = store.useSnapshot()
//   const name = isCountryFeature(hoveredFeature)
//     ? hoveredFeature.properties.ISO_A3
//     : isStateFeature(hoveredFeature)
//     ? hoveredFeature.properties.name
//     : null
//   const regionProjects = PROJECTS.filter(
//     (p) => name && p.regions.includes(name),
//   )
//   const project = regionProjects[0]
//   return project ? (
//     <div className=''>
//       <div className='text-2xl font-bold'>{project.name}</div>
//       <div className='text-2xl font-bold'>{project.description}</div>
//     </div>
//   ) : null
// }
