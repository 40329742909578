import { useEffect, useRef } from 'react'
import dynamic from 'next/dynamic'
import { cn } from '@/lib/utils'

import { Breakpoints } from '@acme/shared'

import { Footer } from '~/components/Footer'
import { Projects } from '~/components/Projects'
import { Subtitle } from '~/components/Subtitle'
import { Team } from '~/components/Team'
import { Title } from '~/components/Title'
import { Responsive } from '~/utils/responsive'

const Globe = dynamic(
  () => import('~/components/Globe').then((g) => g.GlobeComponent),
  { ssr: false },
)

const DesktopHomePage = () => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    ref.current?.addEventListener('scroll', () => {
      console.log('scroll 5')
    })
  }, [])
  return (
    <div
      className={cn(
        'flex min-h-screen flex-col items-center bg-[#15162c] pt-24 text-white',
        // mountain dev wraps after this
        'min-w-[375px]',
      )}
      onScrollCapture={() => {
        console.log('scroll 1')
      }}
    >
      <div
        className='relative flex w-[90%] max-w-7xl flex-col'
        onScrollCapture={() => {
          console.log('scroll 2')
        }}
      >
        <div
          className='flex flex-row justify-between pb-16'
          onScrollCapture={() => {
            console.log('scroll 3')
          }}
        >
          <div
            ref={ref}
            className='z-10 -mr-32 max-w-lg'
            onScrollCapture={() => {
              console.log('scroll 4')
            }}
          >
            <Title />
            <div className='h-40' />
            <Projects />
            <div className='h-40' />
            <Team />
            <div className='h-40' />
            <Subtitle />
            <div className='h-60' />
          </div>
          <div className='-p-4 sticky top-0 self-start overflow-hidden'>
            <Globe />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

const MobileHomePage = () => {
  return (
    <div className='flex min-h-screen flex-col items-center bg-[#15162c] px-[10%] text-white'>
      <div className='mt-8 flex w-full flex-col'>
        <Title />
        <div className='h-16' />
        <Subtitle />
        <div className='flex w-full justify-center'>
          <Globe />
        </div>
        <Projects />
        <div className='h-16' />
        <Team />
        <Footer />
      </div>
    </div>
  )
}

export default function HomePage() {
  return (
    <>
      <Responsive minWidth={Breakpoints.xl}>
        <DesktopHomePage />
      </Responsive>
      <Responsive maxWidth={Breakpoints.xl}>
        <MobileHomePage />
      </Responsive>
    </>
  )
}
